import { Formik } from "formik";
import React, { PureComponent } from "react";
import { Button, FormGroup, Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import * as Yup from "yup";
import _ from 'lodash';
import saveProtocolo from "../../images/icon-save.svg";
import { translate } from "../../_helpers/messages.helper";
import { defaultService } from "../../_services/defaultService";
import { modeloService } from "../../_services/modelo.service";
import { protocoloAnamneseService } from "../../_services/protocoloAnamnese.service";
import FormInput from "../inputs/FormInput";
import SelectInput from "../inputs/SelectInput";
import InputViewEdit from "../inputs/InputViewEdit";
import { marcacaoService } from "../../_services/marcacao.service";
import FormTextCKEditor from "../inputs/FormTextCKEditor";
import { medicamentoService } from "../../_services/medicamento.service";
import { prontuarioIntegracaoService } from "../../_services/prontuarioIntegracao.service";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import { dateHelper } from '../../_helpers/date.helper'
import { userService } from "../../_services/user.service";

const stringSimilarity = require('string-similarity');

const GerarDocumentoValidate = Yup.object().shape({

});

// type State = {
// 	marcacao: any,
// };

const modifiers = {
	preventOverflow: {
		enabled: false
	},
	flip: {
		enabled: false
	}
};

export class ModalConsultarProntuario extends PureComponent<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			prontuarios: [],
			medicoLogado: userService.getCurrentUser()
		}

	}

	cancelar = () => {
		this.props.toogle();
	};

	componentDidMount() {

		if (this.props.marcacao && this.props.marcacao.paciente) {
			this.selecionaPaciente({ nome: this.props.marcacao.paciente.nome });
		}
	}

	selecionaPaciente = (paciente) => {
		const { marcacao } = this.props;
		prontuarioIntegracaoService.findProntuario(marcacao.estabelecimento.id, paciente.nome).then(
			(response) => {
				let prontuarios = response.data;
				this.setState({ prontuarios });
			},
			(error) => {
				console.log(error);
			}
		);

	}
	addProntuarioPaciente = (idPacienteProntuario, nomePaciente) => {
		let { paciente, estabelecimento } = this.props.marcacao;
		prontuarioIntegracaoService.save(idPacienteProntuario, paciente.id, estabelecimento.id).then((r) => {
			this.selecionaPaciente({ nome: nomePaciente });
			this.props.loadListAnamnese();
		});
	}

	verificaProntuarioMedico = (crmMedico, nomeMedico) => {
		const { medicoLogado } = this.state;
		let numeroConselho = _.get(medicoLogado, 'numeroConselho');
		let nomeMedicoLogado = _.get(medicoLogado, 'nome');
		if (_.isEqual(crmMedico, numeroConselho)) {
			const similarity = stringSimilarity.compareTwoStrings(nomeMedico.toUpperCase(), nomeMedicoLogado.toUpperCase());
			if (similarity > 0.7) {
				return true;
			}
		}
		return false;
	}

	render() {
		const { entity, callbackOnSave, marcacao } = this.props;
		let _this = this;
		console.log(this.state.prontuarios);


		return (
			<React.Fragment>

				{marcacao && marcacao.estabelecimento && (
					<Modal
						isOpen={this.props.isOpen}
						toggle={this.props.toogle}
						backdrop="static"
						modalClassName=""
						className="modal-anexos modal-xl"
						centered={true}
						modifiers={modifiers}
						target={this.props.target}
					>
						<div className="close-menu">
							<div className="action" onClick={this.props.toogle}>
								Fechar
							</div>
						</div>
						{/* <Formik
							validationSchema={GerarDocumentoValidate}
							validateOnBlur={false}
							validateOnChange={false}
							enableReinitialize={true}
							initialValues={{ paciente: this.props.marcacao.paciente.nome }}
							onSubmit={(values) => {

								_.set(values, 'marcacaoVO', marcacao);
								_.set(_.get(values, 'documentoVO'), 'textoCorpo', _.get(values, 'textoCorpoEditado'));
								let newValues = _.pick(values, ['documentoVO', 'marcacaoVO']);
								this.props.load(true);
								modeloService.imprimir(newValues).then(
									(response) => {
										var base64 = _.get(response, 'data.data.data');
										let asciiString = atob(base64);
										let array = new Uint8Array(
											[...asciiString].map((char) => char.charCodeAt(0))
										);
										const file = new Blob([array], { type: 'application/pdf' });
										const fileURL = URL.createObjectURL(file);
										this.props.toogle();
										this.setState({ showCorpoModelo: false })
										window.open(fileURL);
										this.props.load(false);

									},
									(error) => {
										console.log(error);
										this.props.load(false);
									}
								);

							}}
							ref={form => {
								this.formRef = form;
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								validationSchema,
								validateForm,
								setValues
							}) => (
								<form onSubmit={handleSubmit}> */}
						<ModalBody className={"p-0"}>
							<div className="">
								<div className="row">
									<div className="col-12 text-center iconProtocolo">
										<img src={saveProtocolo} alt="anexo" />
									</div>
									<div className="col-12 text-center mb-2">
										<h2 className="font-weight-bold">Pronturário(s) importados de {this.props.marcacao.paciente ? this.props.marcacao.paciente.nome : ""} </h2>
									</div>

								</div>
								{this.state.prontuarios && this.state.prontuarios.length >= 1 && (
									<>
										<div className="form-row">
											<div className="col-3 title-tab">Medico</div>
											<div className="col-1 title-tab">Data</div>
											<div className="col-6 title-tab">Prontuario</div>
											<div className="col-2 title-tab">Adicionar ao prontuário</div>
										</div>

										{this.state.prontuarios.map((p, index) => {
											return (
												<div key={index} className={`form-row line ${index % 2 == 0 ? 'bkg-branco' : ''}`}>
													<div className="col-3"> {p.nomeMedico}</div>
													<div className="col-1">{dateHelper.format(p.dataAtendimento, { mode: 'DATE' })}</div>
													<div className="col-6" id={`Helper_${p.id}`}>{p.prontuario.substring(0, 250)}</div>
													<ToolTipHelp
														target={`Helper_${p.id}`}
														message={p.prontuario}
													/>

													<div className="col-2">
														{this.verificaProntuarioMedico(p.crm, p.nomeMedico) && (
															<Button
																color="primary"
																onClick={() => { this.addProntuarioPaciente(p.id, p.nomePaciente) }}
																style={{ marginBottom: "1rem" }}
															>
																Adicionar
															</Button>
														)}
														{!this.verificaProntuarioMedico(p.crm, p.nomeMedico) && (
															<p className="f-10">Não é possível importar prontuário de outro profissional.</p>
														)}
													</div>
												</div>
											);
										})
										}
									</>
								)}
								{this.state.prontuarios.length == 0 && (
									<div className="row">
										<div className="col-12 text-center my-3">Nenhuma anamnese importada para esse paciente.</div>
									</div>
								)}

							</div>
						</ModalBody>
						{/* </form>

							)}
						</Formik> */}

					</Modal>
				)
				}
			</React.Fragment >
		);
	}
}
